import {
  PDP_SHIPPING_ESTIMATE,
  PDP_SHIPPING_OPTIONS_TEXTS,
} from '../../../constants'
import { useFormattedPrice } from '../../../sdk/product/useFormattedPrice'
import Price from '../../ui/Price'
import styles from './product-shipping-options.module.scss'

export function ProductShippingOptionItems({
  shippingOptions,
  modal,
}: {
  shippingOptions: ShippinngSLAItem[]
  modal: boolean
}) {
  return (
    <>
      <div className={styles.shippingOptionsItemContainer}>
        {shippingOptions?.map((option: ShippinngSLAItem, index: number) => {
          if (!modal && index >= 3) {
            return
          }

          return (
            <div key={index} className={styles.shippingOptionItem}>
              <h4 className={styles.shippingOptionItemName}>
                {option?.deliveryChannel !== 'delivery'
                  ? option?.name
                  : option?.id === 'ED'
                  ? 'Envío en el día'
                  : option?.id}
              </h4>
              <h4 className={styles.shippingOptionItemTime}>
                {option?.id
                  .toLowerCase()
                  .includes(PDP_SHIPPING_OPTIONS_TEXTS.shippingTypeAgree)
                  ? ''
                  : (PDP_SHIPPING_ESTIMATE as any)[option?.shippingEstimate]}
              </h4>
              <div className={styles.shippingOptionItemPrice}>
                {option?.id
                  .toLowerCase()
                  .includes(PDP_SHIPPING_OPTIONS_TEXTS.shippingTypeAgree) && (
                  <h4>{PDP_SHIPPING_OPTIONS_TEXTS.shippingToAgreeText}</h4>
                )}
                {!option?.id
                  .toLowerCase()
                  .includes(PDP_SHIPPING_OPTIONS_TEXTS.shippingTypeAgree) &&
                  option?.price === 0 && (
                    <h4>{PDP_SHIPPING_OPTIONS_TEXTS.shippingFree}</h4>
                  )}
                {!option?.id
                  .toLowerCase()
                  .includes(PDP_SHIPPING_OPTIONS_TEXTS.shippingTypeAgree) &&
                  option?.price !== 0 && (
                    <Price
                      value={option?.price / 100}
                      formatter={useFormattedPrice}
                      testId="price"
                      data-value={option?.price / 100}
                      variant="shipping"
                      classes="text"
                      SRText="Shipping Price:"
                    />
                  )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
