/* eslint-disable no-restricted-globals */
import Price from 'src/components/ui/Price'
import { useDiscountPercent } from 'src/sdk/product/useDiscountPercent'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import { usePricePerKg } from 'src/utils/usePricePerKg'

import { PRICE_TYPES } from '../../../constants'
import isProductMKT from '../../../utils/productMKTChecker'
import { ProductMKTPrice } from '../../ui/Price/productMKTPrice'

export const ProductPrice = ({
  data,
  multicurrecySellingPrice,
  multicurrencyListPrice,
}: any) => {
  const {
    product: {
      offers: {
        offers: [
          {
            listPrice,
            seller: { identifier },
          },
        ],
        lowPrice,
      },
      unitMultiplier,
      measurementUnit,
    },
  } = data

  const isProductPerKg = measurementUnit === 'kg'

  const realPrice =
    unitMultiplier && unitMultiplier > 1 && unitMultiplier < 2
      ? lowPrice * unitMultiplier
      : lowPrice

  const productPerKgOffers = usePricePerKg({
    listPrice,
    unitMultiplier: unitMultiplier ?? 1,
    lowPrice: realPrice,
  })

  const originalPrice = isProductPerKg
    ? productPerKgOffers.originalProductPrice
    : listPrice

  const actualPrice = isProductPerKg
    ? productPerKgOffers.actualProductPrice
    : lowPrice

  const discountPercentage = useDiscountPercent(listPrice, lowPrice)

  const discountValue = isProductPerKg
    ? productPerKgOffers.discountPercentage
    : discountPercentage

  return (
    <section>
      <div data-fs-text-price-unit-pdp>
        {(discountValue && !isProductMKT(identifier)) ||
        (discountValue &&
          isProductMKT(identifier) &&
          multicurrecySellingPrice <= 0) ? (
          <div data-fs-product-details__discount-wrapper>
            <Price
              value={originalPrice}
              formatter={useFormattedPrice}
              testId="list-price"
              data-value={originalPrice}
              variant="listing"
              classes="text__legend"
              SRText="Original price:"
            />
            <p>{`${discountValue}% OFF`}</p>
          </div>
        ) : (
          <></>
        )}
        {multicurrencyListPrice && isProductMKT(identifier) ? (
          <div>
            <ProductMKTPrice
              priceValue={multicurrencyListPrice}
              priceType={PRICE_TYPES.listPrice}
              componentToRender="productDetails"
            />
          </div>
        ) : (
          <></>
        )}
        {!isProductMKT(identifier) ||
        (isProductMKT(identifier) && multicurrecySellingPrice <= 0) ? (
          <div>
            <Price
              value={actualPrice}
              formatter={useFormattedPrice}
              testId="price"
              data-value={actualPrice}
              variant="spot"
              classes="text__lead"
              SRText="Sale Price:"
            />
          </div>
        ) : null}
        {multicurrecySellingPrice &&
        isProductMKT(identifier) &&
        multicurrecySellingPrice > 0 ? (
          <ProductMKTPrice
            priceValue={
              typeof multicurrecySellingPrice === 'number'
                ? multicurrecySellingPrice
                : actualPrice
            }
            priceType={PRICE_TYPES.sellingPrice}
            componentToRender="productDetails"
          />
        ) : null}
      </div>

      {isProductPerKg && !isProductMKT(identifier) ? (
        <div data-fs-price-unit-container data-fs-text-price-unit-pdp>
          <Price
            value={productPerKgOffers.actualKgPrice}
            formatter={useFormattedPrice}
            data-fs-text-price-unit-pdp
            SRText="Sale Price:"
          />
          <p data-fs-text-price-unit-pdp>x {measurementUnit}</p>
        </div>
      ) : (
        <></>
      )}
    </section>
  )
}
