export const GeneralSpecifications = ({
  especificacionesGenerales,
}: ProductNutritionalInfo) => {
  return (
    <div data-fs-product-details-content>
      {especificacionesGenerales?.map((e) => {
        if (!e?.display) {
          return null
        }

        return (
          <>
            {e?.title !== '' && (
              <h3 data-fs-product-specifications-title>{e?.title}</h3>
            )}

            {e?.items?.map((item, index) => {
              if (item?.value === '') {
                return null
              }

              return (
                <div data-fs-product-specifications-item key={index}>
                  <span data-fs-product-specifications-item-name>
                    {item?.name}
                  </span>
                  <span>{item?.value}</span>
                </div>
              )
            })}
          </>
        )
      })}
    </div>
  )
}
