import { ITEMS_PER_SECTION } from 'src/constants'
import type { PropsWithChildren } from 'react'

import ProductCardSkeleton from '../ProductCardSkeleton'

interface Props {
  loading?: boolean
}

function ProductShelfSkeleton({
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  return loading ? (
    <ul data-fs-product-shelf-items className="layout__content">
      {Array.from(
        { length: ITEMS_PER_SECTION },
        (_, index) =>
          index < 7 && (
            <li key={String(index)}>
              <ProductCardSkeleton sectioned bordered showActions />
            </li>
          )
      )}
    </ul>
  ) : (
    <>{children}</>
  )
}

export default ProductShelfSkeleton
