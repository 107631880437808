import SkeletonElement from '../SkeletonElement'
import styles from './pdp-shipping-options-skeleton.module.scss'

export const PDPShippingOptionsSkeleton = () => {
  return (
    <div className={styles.sippingOptionsContainer}>
      <div className={styles.shippingOptionsTitle}>
        <SkeletonElement type="text" />
      </div>
      <div className={styles.shippingOptionsRegionalization}>
        <SkeletonElement type="text" />
      </div>
      <div>
        <div className={styles.shippingOptionsItemSubtitle}>
          <SkeletonElement type="text" />
        </div>
        <div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
        </div>
      </div>
      <div>
        <div className={styles.shippingOptionsItemSubtitle}>
          <SkeletonElement type="text" />
        </div>
        <div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
          <div className={styles.shippingOptionsItem}>
            <SkeletonElement type="text" />
          </div>
        </div>
      </div>
      <div className={styles.shippingOptionsMore}>
        <SkeletonElement type="text" />
      </div>
    </div>
  )
}
