type NutritionalTableProps = {
  tablaNutricional: TablaNutricional[]
  declaracionSimplificadaNotas?: string
}

function renderNutritionalImage(fileName: string) {
  return (
    <img
      alt={fileName}
      src={`https://tatauy.vtexassets.com/arquivos/${fileName}.png`}
    />
  )
}

const NutritionalTable = ({
  tablaNutricional,
  declaracionSimplificadaNotas,
}: NutritionalTableProps) => {
  const filteredItemsByDisplay = tablaNutricional?.filter(
    (e) => e.display === true
  )

  const displayNutritionalTable = filteredItemsByDisplay.length > 0

  return (
    <div>
      {displayNutritionalTable && (
        <>
          <table data-fs-product-details-table>
            <thead data-fs-product-details-tablehead>
              <tr>
                <th />
                <th>Cantidad por porción</th>
                <th>VD*</th>
              </tr>
            </thead>
            <tbody>
              {filteredItemsByDisplay.map((item, index) => {
                return (
                  <tr key={index}>
                    <td data-fs-product-details-td-name>{item?.name}</td>
                    <td data-fs-product-details-td-info>{item?.cantidad}</td>
                    <td data-fs-product-details-td-info>{item?.vd}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {declaracionSimplificadaNotas && (
            <p data-fs-product-details-table-note>
              {`* ${declaracionSimplificadaNotas}.`}
            </p>
          )}
        </>
      )}
    </div>
  )
}

export const NutritionalInfo = ({
  listaDeIngredientes,
  medidaDeLaPorcionCantidad,
  medidaDeLaPorcionUnidad,
  medidaDeLaPorcionCaseraCantidad,
  medidaDeLaPorcionCaseraUnidad,
  alertasOctogonosExcesoDeAzucar,
  alertasOctogonosExcesoDeGrasas,
  alertasOctogonosExcesoDeGrasasSaturadas,
  alertasOctogonosExcesoDeSodio,
  tablaNutricional,
  declaracionSimplificadaNotas,
}: ProductNutritionalInfo) => {
  return (
    <div data-fs-product-details-content>
      <div data-fs-product-details-image-container>
        {alertasOctogonosExcesoDeAzucar &&
          renderNutritionalImage('alertasOctogonosExcesoDeAzucar')}
        {alertasOctogonosExcesoDeGrasas &&
          renderNutritionalImage('alertasOctogonosExcesoDeGrasas')}
        {alertasOctogonosExcesoDeGrasasSaturadas &&
          renderNutritionalImage('alertasOctogonosExcesoDeGrasasSaturadas')}
        {alertasOctogonosExcesoDeSodio &&
          renderNutritionalImage('alertasOctogonosExcesoDeSodio')}
      </div>

      {listaDeIngredientes && (
        <div data-fs-product-details-content-item>
          <span data-fs-product-details-content-title>Ingredientes: </span>
          <p>{listaDeIngredientes}</p>
        </div>
      )}

      <div data-fs-product-details-content-item>
        {medidaDeLaPorcionCantidad &&
          medidaDeLaPorcionUnidad &&
          medidaDeLaPorcionCaseraCantidad &&
          medidaDeLaPorcionCaseraUnidad && (
            <>
              <span data-fs-product-details-content-title>
                Descripción de la porción:
              </span>
              <p>
                {`${medidaDeLaPorcionCantidad} ${medidaDeLaPorcionUnidad} (${medidaDeLaPorcionCaseraCantidad} ${medidaDeLaPorcionCaseraUnidad})`}
              </p>
            </>
          )}
      </div>
      {tablaNutricional && (
        <NutritionalTable
          tablaNutricional={tablaNutricional}
          declaracionSimplificadaNotas={declaracionSimplificadaNotas}
        />
      )}
    </div>
  )
}
