import { useSession } from 'src/sdk/session'

export const useBraindwSendGA = () => {
  const { currency } = useSession()

  const sendEventGA = (
    values: any,
    eventAction?: string,
    type?: string
  ): void | null => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer ?? []
    }

    if (!values) {
      return null
    }

    const sendAddToCart = () => {
      const {
        quantity,
        price,
        itemOffered: {
          sku,
          name: productName,
          brand: { name: brandName },
        },
      } = values

      const eventAddToCart = {
        event: 'addToCartBraindw',
        ecommerce: {
          currencyCode: currency,
          add: {
            actionField: {
              list: eventAction,
              action: 'add',
            },
            products: [
              {
                name: productName,
                id: sku,
                price,
                brand: brandName,
                category: '',
                quantity,
                dimension: 'Braindw',
              },
            ],
          },
        },
      }

      window.dataLayer.push(eventAddToCart)
    }

    const sendImpression = () => {
      const prods = values?.map((val: any) => ({
        name: val?.name,
        id: val?.sku,
        price: val?.offers?.price,
        brand: val?.brand,
        category: '',
        list: eventAction,
        position: 1,
        dimension: 'Braindw',
      }))

      const eventImpression = {
        ecommerce: {
          currencyCode: currency,
          impressions: [prods],
        },
      }

      window.dataLayer.push(eventImpression)
    }

    const sendClickEvent = () => {
      const {
        quantity,
        price,
        itemOffered: {
          sku,
          name: productName,
          brand: { name: brandName },
        },
      } = values

      const eventClick = {
        event: 'clickBraindw',
        ecommerce: {
          currencyCode: currency,
          click: {
            actionField: {
              list: eventAction,
            },
            products: [
              {
                name: productName,
                id: sku,
                price,
                brand: brandName,
                category: '',
                quantity,
                dimension: 'Braindw',
              },
            ],
          },
        },
      }

      window.dataLayer.push(eventClick)
    }

    switch (type) {
      case 'addToCartBraindw':
        sendAddToCart()
        break

      case 'eventImpression':
        sendImpression()
        break

      case 'clickEvent':
        sendClickEvent()
        break

      default:
        return null
    }
  }

  return {
    sendEventGA,
  }
}
