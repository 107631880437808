import { useEffect, useState } from 'react'
import Accordion, { AccordionItem } from 'src/components/ui/Accordion'

import { GeneralSpecifications } from './GeneralSpecifications'
import { NutritionalInfo } from './NutritionalInfo'
import { ProductDescription } from './ProductDescription'
import { ProductSellerInfos } from './ProductSellerInfos'

export const ProductDetailsContent = ({
  nutritionalData,
  description,
  sellerInfos,
}: any) => {
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([1, 4])
  )

  useEffect(() => {
    setIndicesExpanded(
      !nutritionalData?.tablaNutricional ? new Set([0, 3]) : new Set([1, 4])
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description])

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
    }
  }

  const accordionData = [
    {
      buttonLabel: 'Descripción',
      children: <ProductDescription description={description} />,
    },
    {
      buttonLabel: 'Información Nutricional',
      children: <NutritionalInfo {...nutritionalData} />,
    },
    {
      buttonLabel: 'Especificaciones Generales',
      children: <GeneralSpecifications {...nutritionalData} />,
    },
    {
      buttonLabel: 'Vendido por',
      children: <ProductSellerInfos sellerInfos={sellerInfos} />,
    },
  ]

  return (
    <section data-fs-product-details__content>
      <Accordion
        expandedIndices={indicesExpanded}
        onChange={onChange}
        data-fs-product-details__description-accordion
      >
        {accordionData.map((e, index) => {
          if (e.buttonLabel === 'Descripción' && !description) {
            return <></>
          }

          if (
            (e.buttonLabel === 'Información Nutricional' ||
              e.buttonLabel === 'Especificaciones Generales') &&
            !nutritionalData?.tablaNutricional
          ) {
            return <></>
          }

          if (e.buttonLabel === 'Vendido por' && !sellerInfos.sellerInfos) {
            return <></>
          }

          return (
            <AccordionItem
              iconNameContracted="ArrowDown"
              iconNameExpanded="ArrowUp"
              isExpanded={indicesExpanded.has(index)}
              buttonLabel={e.buttonLabel}
              data-accordion-item
              key={index}
            >
              {e.children}
            </AccordionItem>
          )
        })}
      </Accordion>
    </section>
  )
}
