import { useState } from 'react'
import GeoLocatorModal from 'src/components/common/Geolocator/GeoLocatorModal'
import Icon from 'src/components/ui/Icon'
import { PDP_SHIPPING_OPTIONS_TEXTS } from 'src/constants'

import { PDPShippingOptionsSkeleton } from '../../skeletons/PDPShippingOptionsSkeleton/PDPShippingOptionsSkeleton'
import { ProductShippingOptionItems } from './ProductShippingOptionItems'
import { ProductShippingOptionsModal } from './ProductShippingOptionsModal'
import styles from './product-shipping-options.module.scss'

export function ProductShippingOptions({
  delivery,
  pickupoint,
  loading,
}: {
  delivery: ShippinngSLAItem[]
  pickupoint: ShippinngSLAItem[]
  loading: boolean
}) {
  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const locationData = HAS_WINDOW
    ? JSON.parse(
        localStorage?.getItem(
          PDP_SHIPPING_OPTIONS_TEXTS.shippingLocalStorageKey
        ) ?? '{}'
      )
    : '{}'

  const [selectedOption, setSelectedOption] = useState(locationData?.Name ?? '')
  const [showGeolocatorModal, setShowGeolocatorModal] = useState(false)
  const [newLocationData, setNewLocationData] = useState(locationData ?? {})
  const [shippingModalOpened, setShippingModalOpened] = useState<boolean>(false)

  if (loading) {
    return <PDPShippingOptionsSkeleton />
  }

  const isPickupAndDeliveryEmpty =
    pickupoint.length === 0 && delivery.length === 0

  if (!loading && !isPickupAndDeliveryEmpty) {
    return (
      <div className={styles.shippingMainContainer}>
        <div className={styles.shippingOptionsTitleContainer}>
          <Icon name="Truck" width="24px" height="24px" />
          <h3>{PDP_SHIPPING_OPTIONS_TEXTS.shippingOptionsTitle}</h3>
        </div>
        <div className={styles.shippingOptionsChangerContainer}>
          <Icon name="MapPin" width="24px" height="24px" />
          <h4>
            {PDP_SHIPPING_OPTIONS_TEXTS.shipTo} {locationData?.description}
          </h4>
          <button
            className={styles.shippingOptiosChangerButton}
            onClick={() => setShowGeolocatorModal(true)}
          >
            {PDP_SHIPPING_OPTIONS_TEXTS.shippingChange}
            <Icon name="ChangeShippingIcon" width="18px" height="18px" />
          </button>
          {showGeolocatorModal && (
            <GeoLocatorModal
              setShowGeo={setShowGeolocatorModal}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              newLocationData={newLocationData}
              setNewLocationData={setNewLocationData}
            />
          )}
        </div>

        <div className={styles.shippingOptionsContainer}>
          <div className={styles.shippingTypeOptionContainer}>
            <div className={styles.shippingOptionsSubtitleContainer}>
              <Icon name="Truck" width="15px" height="15px" />
              <h3>{PDP_SHIPPING_OPTIONS_TEXTS.shippingDeliverySubtitle}</h3>
            </div>
            <ProductShippingOptionItems
              shippingOptions={delivery}
              modal={false}
            />
          </div>

          <div>
            <div className={styles.shippingOptionsSubtitleContainer}>
              <Icon name="PickupTend" width="14px" height="14px" />
              <h3>{PDP_SHIPPING_OPTIONS_TEXTS.shippingPickupSubtitle}</h3>
            </div>
            <ProductShippingOptionItems
              shippingOptions={pickupoint}
              modal={false}
            />
          </div>
        </div>

        <button
          onClick={() => {
            setShippingModalOpened(true)
          }}
          className={styles.shippingMoreOptionsButton}
        >
          <h5>{PDP_SHIPPING_OPTIONS_TEXTS.shippingMoreOptions}</h5>
        </button>

        {shippingModalOpened && (
          <ProductShippingOptionsModal
            shippingOptionsDelivery={delivery}
            shippingOptionsPickupoint={pickupoint}
            setShippingModalOpened={setShippingModalOpened}
          />
        )}
      </div>
    )
  }

  return <></>
}
