export default function CloseIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53035 1.53033C9.82325 1.23744 9.82325 0.762563 9.53035 0.46967C9.23746 0.176777 8.76259 0.176777 8.46969 0.46967L9.53035 1.53033ZM0.46967 8.46969C0.176777 8.76259 0.176777 9.23746 0.46967 9.53035C0.762563 9.82325 1.23744 9.82325 1.53033 9.53035L0.46967 8.46969ZM1.53036 0.46967C1.23746 0.176777 0.762589 0.176777 0.469695 0.46967C0.176802 0.762563 0.176802 1.23744 0.469695 1.53033L1.53036 0.46967ZM8.46972 9.53035C8.76261 9.82325 9.23749 9.82325 9.53038 9.53035C9.82327 9.23746 9.82327 8.76259 9.53038 8.46969L8.46972 9.53035ZM8.46969 0.46967L0.46967 8.46969L1.53033 9.53035L9.53035 1.53033L8.46969 0.46967ZM0.469695 1.53033L8.46972 9.53035L9.53038 8.46969L1.53036 0.46967L0.469695 1.53033Z"
        fill="black"
      />
    </svg>
  )
}
