import Icon from 'src/components/ui/Icon'
import { PDP_SHIPPING_OPTIONS_TEXTS } from 'src/constants'

import styles from './product-shipping-options-modal.module.scss'
import { ProductShippingOptionItems } from './ProductShippingOptionItems'

export function ProductShippingOptionsModal({
  shippingOptionsDelivery,
  shippingOptionsPickupoint,
  setShippingModalOpened,
}: {
  shippingOptionsDelivery: ShippinngSLAItem[]
  shippingOptionsPickupoint: ShippinngSLAItem[]
  setShippingModalOpened: (value: boolean) => void
}) {
  const HAS_WINDOW =
    typeof window !== 'undefined' && typeof window !== undefined

  const locationData = HAS_WINDOW
    ? JSON.parse(
        localStorage?.getItem(
          PDP_SHIPPING_OPTIONS_TEXTS.shippingLocalStorageKey
        ) ?? '{}'
      )
    : '{}'

  return (
    <div className={styles.shippingOptionsModalContainerOverlay}>
      <div className={styles.shippingOptionsModalContainer}>
        <div className={styles.shippingOptionsModalHeader}>
          <h1>{PDP_SHIPPING_OPTIONS_TEXTS.shippingModalTitle}</h1>
          <button
            className={styles.closeButton}
            onClick={() => {
              setShippingModalOpened(false)
            }}
          >
            <Icon name="ShippingModalCloseIcon" width="14px" height="14px" />
          </button>
        </div>

        <div className={styles.shippingOptionsModalSelectedAddress}>
          <h3>
            {PDP_SHIPPING_OPTIONS_TEXTS.shippingModalRegionalizedSubtitle}
          </h3>
          <div className={styles.selectedAddress}>
            <Icon name="MapPin" width="24px" height="24px" />
            <h4>
              {PDP_SHIPPING_OPTIONS_TEXTS.shipTo}{' '}
              <span>{locationData?.description}</span>
            </h4>
          </div>
        </div>

        <div className={styles.shippingPossibleOptionsModalContainer}>
          <div className={styles.possibleOption}>
            <div className={styles.shippingPossibleOptionsSubtitleContainer}>
              <Icon name="Truck" width="15px" height="15px" />
              <h3>{PDP_SHIPPING_OPTIONS_TEXTS.shippingDeliverySubtitle}</h3>
            </div>
            <ProductShippingOptionItems
              shippingOptions={shippingOptionsDelivery}
              modal
            />
          </div>

          <div className={styles.possibleOption}>
            <div className={styles.shippingPossibleOptionsSubtitleContainer}>
              <Icon name="PickupTend" width="14px" height="14px" />
              <h3>{PDP_SHIPPING_OPTIONS_TEXTS.shippingPickupSubtitle}</h3>
            </div>
            <ProductShippingOptionItems
              shippingOptions={shippingOptionsPickupoint}
              modal
            />
          </div>
        </div>
      </div>
    </div>
  )
}
