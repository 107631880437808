import type { PropsWithChildren } from 'react'

import Shimmer from '../Shimmer'
import SkeletonElement from '../SkeletonElement'
import styles from './price-skeleton.module.scss'

interface Props {
  bordered?: boolean
  sectioned?: boolean
  showActions?: boolean
  loading?: boolean
}

function PriceSkeleton({
  bordered,
  children,
  loading = true,
}: PropsWithChildren<Props>) {
  return loading ? (
    <div
      className={styles.fsPriceSkeleton}
      data-store-price-skeleton
      data-bordered={bordered}
    >
      <SkeletonElement type="price" />

      <Shimmer />
    </div>
  ) : (
    <>{children}</>
  )
}

export default PriceSkeleton
