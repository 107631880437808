type ProductSellerInfosProps = {
  sellerInfos?: {
    loading: boolean
    sellerInfos: SellersInfos
  }
}

export const ProductSellerInfos = ({
  sellerInfos,
}: ProductSellerInfosProps) => {
  if (!sellerInfos) {
    return null // or some other fallback UI
  }

  if (
    sellerInfos.loading ||
    !sellerInfos?.sellerInfos?.sellerRegistrationNumber ||
    !sellerInfos?.sellerInfos?.sellerRegistrationName
  ) {
    return null
  }

  return (
    <div data-fs-product-details-content>
      <p data-fs-product-details-content-sellerInfoText>
        Razón Social: {sellerInfos?.sellerInfos?.sellerRegistrationName}
      </p>
      <p data-fs-product-details-content-sellerInfoText>
        Rut: {sellerInfos?.sellerInfos?.sellerRegistrationNumber}
      </p>
    </div>
  )
}
