import type { ChangeEvent } from 'react'

import SkuSelector from './SkuSelector'
import type { SkuVariantsByName } from './skuVariants'
import { navigateToSku } from './skuVariants'

interface Props {
  slugsMap: Record<string, string>
  availableVariations: SkuVariantsByName
  activeVariations: Record<string, string>
  results: Variant[]
}

interface AdditionalProperty {
  name: string
  value: any
}

interface Offer {
  availability: string
  quantity: number
}

interface Variant {
  stock: boolean
  sku: string
  slug: string
  additionalProperty: AdditionalProperty[]
  offers: {
    offers: Offer[]
  }
}

const DOMINANT_SKU_SELECTOR_PROPERTY = 'Color'

function CustomSelectors({
  slugsMap,
  availableVariations,
  activeVariations,
  results,
}: Props) {
  const { Color: colorOptions, ...otherSkuVariants } = availableVariations

  const optionSkus = results.map((s) => ({
    label: s.additionalProperty[0].name,
    value: s.additionalProperty[0].value,
    disabled: !s.stock,
    sku: s.sku,
  }))

  function handleOnChange(
    e: ChangeEvent<HTMLInputElement>,
    updatedVariationName: string
  ) {
    const newVariationValue = e.currentTarget.value

    navigateToSku({
      slugsMap,
      updatedVariationName,
      selectorsState: activeVariations,
      updatedVariationValue: newVariationValue,
      dominantSku: DOMINANT_SKU_SELECTOR_PROPERTY,
    })
  }

  return (
    <section>
      {colorOptions && (
        <SkuSelector
          label="Color"
          variant="image"
          options={colorOptions}
          activeValue={activeVariations.Color}
          onChange={(e) => handleOnChange(e, 'Color')}
        />
      )}
      {otherSkuVariants &&
        Object.keys(otherSkuVariants).map((skuVariant) => (
          <SkuSelector
            variant="label"
            key={skuVariant}
            label={skuVariant}
            options={optionSkus}
            activeValue={activeVariations[skuVariant]}
            onChange={(e) => handleOnChange(e, skuVariant)}
          />
        ))}
    </section>
  )
}

export default CustomSelectors
