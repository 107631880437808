/* eslint-disable react-hooks/rules-of-hooks */
import { RadioOption as UIRadioOption } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'

import styles from './sku-selector.module.scss'
import type { SkuSelectorProps, SkuProps } from './UISkuSelector'
import UISkuSelector from './UISkuSelector'

function SkuSelector({ ...props }: SkuSelectorProps) {
  const { options, activeValue, variant } = props
  const orderLetters = (Letters: string) => {
    switch (Letters) {
      case 'S':
        return 0

      case 'M':
        return 1

      case 'L':
        return 2

      case 'XL':
        return 3

      case 'XXL':
        return 4

      default:
        return 5
    }
  }

  const ordernation = (optionsArray: SkuProps[]) => {
    if (optionsArray.find((o) => o.value === 'M')) {
      return optionsArray.sort(
        (a, b) => orderLetters(a.value) - orderLetters(b.value)
      )
    }

    return optionsArray
  }

  const sortedByValueOptions = ordernation(options)

  return (
    <UISkuSelector className={styles.fsSkuSelector} {...props}>
      {sortedByValueOptions.map((option, index) => {
        return (
          <UIRadioOption
            data-fs-sku-selector-option
            key={String(index)}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            checked={option.value === activeValue}
          >
            {variant === 'label' && <span>{option.value}</span>}
            {variant === 'image' && 'src' in option && (
              <span>
                <Image
                  src={option.src ?? ''}
                  alt={option.alt}
                  width={20}
                  height={20}
                  loading="lazy"
                  data-fs-sku-selector-option-image
                />
              </span>
            )}
          </UIRadioOption>
        )
      })}
    </UISkuSelector>
  )
}

export default SkuSelector
