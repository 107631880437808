import axios from 'axios'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'

const url = '/api/getSellerInfos'

type CacheID = string

type CacheData = CacheID

const CACHE: Record<CacheData, SellersInfos[]> = {}

const getSellerInfos = async (
  setSellerInfos: Dispatch<SetStateAction<SellersInfos[] | []>>
) => {
  const cacheID = url

  if (CACHE[cacheID] !== undefined) {
    setSellerInfos(CACHE[cacheID])
  } else {
    await axios
      .get(url)
      .then((response) => {
        CACHE[cacheID] = response.data
        setSellerInfos(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const useGetSellerInfos = (sellerId: string) => {
  const [loading, setLoading] = useState(false)
  const [sellerInfos, setSellerInfos] = useState<SellersInfos[] | []>([])

  useEffect(() => {
    setLoading(true)
    getSellerInfos(setSellerInfos).then(() => setLoading(false))
  }, [])

  // /* Get value in mastedata by SellerId from PDP */
  const sellerInfosToReturn = sellerInfos?.find(
    (item: { sellerId: string }) => item?.sellerId === sellerId ?? null
  )

  /* Get minimum value to freeShipping and to buy */
  return {
    sellerInfos: sellerInfosToReturn,
    loading,
  }
}
